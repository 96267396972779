import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // Import setDoc and doc
import { auth, db } from '../firebase'; // Ensure this path is correct

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState(''); // State for username
  const navigate = useNavigate();

  const signUp = async () => {
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add username and email to Firestore
      await setDoc(doc(db, "users", user.uid), {
        username: username,
        email: email,
      });

      console.log('User created:', user.uid);
      navigate('/hello'); // Redirect to HelloWorld page after sign up
    } catch (error) {
      console.error(error.message);
    }
  };

  // Inline styles
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      color: 'white',
      backgroundColor: 'black',
      fontFamily: "'Orbitron', sans-serif",
    },
    title: {
      fontSize: '48px',
      marginBottom: '20px',
    },
    input: {
      margin: '10px 0',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      width: '80%',
      maxWidth: '300px',
    },
    button: {
      margin: '10px 0',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      width: '85%',
      maxWidth: '315px',
    },
  };
;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Sign Up</h2>
      <input
        type="text"
        value={username}
        placeholder="Username"
        onChange={(e) => setUsername(e.target.value)}
        style={styles.input}
      />
      <input
        type="email"
        value={email}
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        style={styles.input}
      />
      <input
        type="password"
        value={password}
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        style={styles.input}
      />
      <button onClick={signUp} style={styles.button}>Sign Up</button>
    </div>
  );
}

export default SignUp;
