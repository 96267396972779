import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase'; // Adjust the import path as necessary

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Inline styles
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      color: 'white',
      backgroundColor: 'black',
      fontFamily: "'Orbitron', sans-serif",
    },
    title: {
      fontSize: '48px',
      marginBottom: '20px',
    },
    input: {
      margin: '10px 0',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      width: '80%',
      maxWidth: '300px',
    },
    button: {
      margin: '10px 0',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      width: '85%',
      maxWidth: '315px',
    },
  };

  const login = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/hello');
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Login</h2>
      <input
        type="email"
        value={email}
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        style={styles.input}
      />
      <input
        type="password"
        value={password}
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        style={styles.input}
      />
      <button onClick={login} style={styles.button}>Login</button>
      <button onClick={() => navigate('/signup')} style={styles.button}>Sign Up</button>
    </div>
  );
}

export default Login;
