// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBlUxm-K1RkAgKj1ioQyc70T7rgnKenmCw",
  authDomain: "null-7a440.firebaseapp.com",
  databaseURL: "https://null-7a440-default-rtdb.firebaseio.com",
  projectId: "null-7a440",
  storageBucket: "null-7a440.appspot.com",
  messagingSenderId: "509426058684",
  appId: "1:509426058684:web:6d9d5e4c0b286ec07dd7b9",
  measurementId: "G-9HNDFTRWKH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);