import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, query, orderBy, onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Adjust the import path as necessary
import { formatRelative } from 'date-fns';
import { signOut } from 'firebase/auth';

const MessageBoard = () => {
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();

  // Adjusted styles
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100vh',
      color: 'white',
      backgroundColor: 'black',
      fontFamily: "'Orbitron', sans-serif",
    },
    logoutButton: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: '#444', // Dark grey, adjust as needed
      color: 'white',
    },
    inputBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 'auto',
      padding: '20px',
    },
    input: {
      margin: '10px 0',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      width: '80%',
      maxWidth: '300px',
    },
    button: {
      margin: '10px 0',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      width: '85%',
      maxWidth: '315px',
    },
    messageContainer: {
      marginTop: '20px',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      width: '80%',
      maxWidth: '600px',
      alignSelf: 'center',
    },
  };

  useEffect(() => {
    const q = query(collection(db, "messages"), orderBy("timestamp", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const msgs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate().getTime(),
      }));
      setMessages(msgs);
    });

    return () => unsubscribe();
  }, []);

  const logout = async () => {
    await signOut(auth);
    navigate('/');
  };

  const getUsername = async (uid) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().username;
    } else {
      return "Anonymous";
    }
  };

  const postMessage = async () => {
    if (!newMessage.trim() || !auth.currentUser) return;
    const username = await getUsername(auth.currentUser.uid);
    await addDoc(collection(db, "messages"), {
      username: username,
      message: newMessage,
      timestamp: new Date(),
    });
    setNewMessage('');
  };

  return (
    <div style={styles.container}>
      <button onClick={logout} style={styles.logoutButton}>Logout</button>
      <div>
        {messages.map(({ id, username, timestamp, message }) => (
          <div key={id} style={styles.messageContainer}>
            <p><strong>{username}</strong> <em>{formatRelative(new Date(timestamp), new Date())}</em></p>
            <p>{message}</p>
          </div>
        ))}
      </div>
      <div style={styles.inputBox}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Write your message..."
          style={styles.input}
        />
        <button onClick={postMessage} style={styles.button}>Post Message</button>
      </div>
    </div>
  );
};

export default MessageBoard;

// Don't forget to include getUsername function if you're using it as previously mentioned.
